import React from "react";
import "./layout.scss";
import Transition from 'components/transition';
import { injectIntl } from "gatsby-plugin-intl";
import Footer from "components/footer";
import { withPrefix } from "gatsby";

const Layout = ({ children, location, intl }) => (
  <Transition location={location}>
    {children}
    <Footer
      icon={!location.pathname.includes(withPrefix('/take-action')) ? "takeAction" : "playAgain"}
      linkTitle={!location.pathname.includes(withPrefix('/take-action')) ? intl.formatMessage({ id: "game-page.footerLinkTitle" }) : intl.formatMessage({ id: "take-action-page.footerLinkTitle" })}
      link={!location.pathname.includes(withPrefix('/take-action')) ? "/take-action" : "/"} />
  </Transition>
)

export default injectIntl(Layout)