module.exports = [{
      plugin: require('/Users/jimmi/Documents/websites/oxfam/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/jimmi/Documents/websites/oxfam/src/layouts/index.js"},
    },{
      plugin: require('/Users/jimmi/Documents/websites/oxfam/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/jimmi/Documents/websites/oxfam/src/data","languages":["en","da","ar","es","fr"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('/Users/jimmi/Documents/websites/oxfam/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
