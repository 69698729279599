// import React from "react"
// import { TransitionGroup, Transition as ReactTransition, } from "react-transition-group";
// import Pattern from "components/pattern";
// import "./transition.scss";
// import "./transition-entering.scss";
// import "./transition-entered.scss";
// import "./transition-exiting.scss";

// const timeout = 500;
// // const getTransitionStyles = {
// //   entering: entering,
// //   entered: entered,
// //   exiting: exiting
// // };

// class Transition extends React.PureComponent {
//   render() {
//     const { children, location } = this.props
//     return (
//       <TransitionGroup component={null}>
//         <Pattern />
//         <ReactTransition
//           key={location.pathname}
//           timeout={{
//             enter: timeout,
//             exit: timeout,
//           }}
//         >
//           {status => (
//             // <div className="transition-container" style={{ ...getTransitionStyles[status] }}>
//             <div className={`transition-container transition-${status}`}>
//               {children}
//             </div>
//           )}
//         </ReactTransition>
//       </TransitionGroup>
//     )
//   }
// }
// export default Transition

import React from "react"
import { TransitionGroup, Transition as ReactTransition, } from "react-transition-group";
import Pattern from "components/pattern";
import "./transition.scss";

const timeout = 500;
const getTransitionStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  }
};

const Transition = ({ children, location }) => (
  <TransitionGroup>
    <Pattern />
    <ReactTransition
      key={location.pathname}
      timeout={{
        enter: timeout,
        exit: timeout,
      }}
    >
      {status => (
        <div className="transition-container" style={{ ...getTransitionStyles[status] }} >
          {children}
        </div>
      )}
    </ReactTransition>
  </TransitionGroup>
)

export default Transition